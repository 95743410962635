/** @jsx jsx */

import React from "react";
import {css, jsx} from "@emotion/core";

const sprechblaseWrapperStyle = css`
    position: relative;
    z-index: 0;
    margin: 0 auto;
    p {
        margin: 0;
    }
    @media screen and (max-width: 1024px) {
        width: 80%;
    }
`;

class Sprechblase extends React.Component {

    childrenContainerRef;

    updateTimer = 0;
    updaterHandle;

    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            height: 0,
        };

        this.childrenContainerRef = React.createRef();
    }

    componentDidMount() {
        this.startUpdateSizes();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.cancelAnimationFrame(this.updaterHandle)
    }

    componentDidUpdate() {
        this.startUpdateSizes();
    }

    render() {
        return this.props.top ? this.renderTop() : this.renderLeft();
    }

    renderLeft() {

        const padding = 10;
        const borderRadius = 25;
        const p = 0.7;
        const noseHeight = 20;
        const noseWidth = 40;

        const width = this.state.width - 2 * (borderRadius - padding);
        const height = this.state.height - 2 * (borderRadius - padding);

        const h1 = height * p - (noseHeight / 2);
        const h2 = height - h1 - noseHeight;

        const strokeWidth = this.props.strokeWidth;

        const svgWidth = this.state.width + 2 * (padding) + strokeWidth + noseWidth;
        const svgHeight = this.state.height + 2 * (padding) + strokeWidth;

        const svgStyle = css`
            position: absolute;
            left: ${(borderRadius - padding) * -0.5 - noseWidth - strokeWidth}px;
            z-index: -1;
        `;

        const pathStyle = css`
            stroke: ${this.props.stroke};
            stroke-width: ${strokeWidth};
            fill: ${this.props.fill};
        `;

        const childrenContainerStyle = css`
            z-index: 1;
            margin-top: ${(padding) + strokeWidth}px;
        `;

        return <div css={sprechblaseWrapperStyle}>
            {<svg css={svgStyle}
                  viewBox={`${(borderRadius) * -1 - strokeWidth / 2 - noseWidth} ${(padding) * -1 - strokeWidth / 2} ${svgWidth} ${svgHeight}`}
                  width={svgWidth} height={svgHeight}>
                <path css={pathStyle} d={`
                    M 0 -10 H ${width} q ${borderRadius} 0 ${borderRadius} ${borderRadius} v ${height} q 0 ${borderRadius} -${borderRadius} ${borderRadius} h ${width * -1} q -${borderRadius} 0 -${borderRadius} -${borderRadius} v ${h1 * -1} l ${noseWidth * -1} ${noseHeight * -0.5} l ${noseWidth} ${noseHeight * -0.5} v ${h2 * -1} q 0 -${borderRadius} ${borderRadius} -${borderRadius} z
                `}/>
            </svg>}
            <div ref={this.childrenContainerRef} css={childrenContainerStyle}>
                {this.props.children}
            </div>
        </div>
    }

    renderTop() {
        const borderRadius = 25;
        const padding = 10;
        const p = 0.5;
        const noseHeight = 20;
        const noseWidth = 40;

        const width = this.state.width - 2 * (borderRadius - padding);
        const height = this.state.height - 2 * (borderRadius - padding);

        const w1 = width * p - noseWidth / 2;
        const w2 = width - w1 - noseWidth;

        const strokeWidth = this.props.strokeWidth;

        const svgWidth = this.state.width + padding * 2 + strokeWidth;
        const svgHeight = this.state.height + padding * 2 + strokeWidth + noseHeight;

        const svgStyle = css`
            position: absolute;
            left: ${padding * -1}px;
            z-index: -1;
        `;

        const pathStyle = css`
            stroke: ${this.props.stroke};
            stroke-width: ${strokeWidth};
            fill: ${this.props.fill};
        `;

        const childrenContainerStyle = css`
            z-index: 1;
            margin-top: ${padding}px;
        `;

        return <div css={sprechblaseWrapperStyle}>
            <svg css={svgStyle}
                 viewBox={`${borderRadius * -1 - strokeWidth / 2} ${borderRadius * -1 - strokeWidth / 2} ${svgWidth} ${svgHeight}`}
                 width={svgWidth} height={svgHeight}>
                <path css={pathStyle} d={`
                    M 0 -${borderRadius} H ${width} q ${borderRadius} 0 ${borderRadius} ${borderRadius} v ${height} q 0 ${borderRadius} -${borderRadius} ${borderRadius} h ${w1 * -1} l ${noseWidth * -0.5} ${noseHeight} l ${noseWidth * -0.5} ${noseHeight * -1} h ${w2 * -1} q -${borderRadius} 0 -${borderRadius} -${borderRadius} v ${height * -1} q 0 -${borderRadius} ${borderRadius} -${borderRadius} z  
                `}/>
            </svg>
            <div ref={this.childrenContainerRef} css={childrenContainerStyle}>
                {this.props.children}
            </div>
        </div>
    }

    handleResize = () => {
        this.startUpdateSizes();
    }

    startUpdateSizes = () => {
        this.updateTimer = performance.now();
        this.updaterHandle = window.requestAnimationFrame(this.updateSizes);
    }

    updateSizes = (timestamp) => {
        if (this.childrenContainerRef.current == null) {
            this.updaterHandle = window.requestAnimationFrame(this.updateSizes);
        }

        const containerSize = this.getContainerSize();
        const dTime = timestamp - this.updateTimer;

        if (this.state.width !== containerSize.width || this.state.height !== containerSize.height) {

            this.updateTimer = timestamp;

            this.updaterHandle = window.requestAnimationFrame(this.updateSizes);

            this.setState({
                width: containerSize.width,
                height: containerSize.height
            });
        } else {
            if (dTime < 500) {
                this.updaterHandle = window.requestAnimationFrame(this.updateSizes);
            }
        }
    }

    getContainerSize = () => {
        const container = this.childrenContainerRef.current;
        const rect = container.getBoundingClientRect();

        return {
            width: rect.width,
            height: rect.height
        };
    }
}

export default Sprechblase;